
import { computed, defineComponent, onMounted, ref, toRaw } from 'vue'
import axios from '@/http'
import { IResListWrap, ISizeType, ISizeValue, ISizeReferences } from '@/interfaces'
import { debounce } from '@/utils/debounce'
import { ElMessage, ElMessageBox } from 'element-plus'

type ISizeReferencesModel = {
	[key: number]: {
		[key: number]: number | undefined
	}
}
type ISizeReferencesStatusModel = {
	[key: number]: {
		[key: number]: {
			status: number
			color?: string
			text?: string
		}
	}
}

export default defineComponent({
	setup() {
		const selectedManagerNameInLocalStorage = 'selectedManagerNameInLocalStorage'
		const sizeTypes = ref<IResListWrap<ISizeType>>()
		const sizeValues = ref<ISizeValue[] | [] | null>(null)
		const sizeReferences = ref<ISizeReferences[] | [] | null>(null)
		const isTypeHasReferencesLink = ref<boolean>(false)
		const removeSizeReferencesStatus = ref<string>('')
		const applySizeReferencesStatus = ref<string[]>([])
		const isFetchedReferences = ref<boolean>(false)
		const sizeReferencesModel = ref<ISizeReferencesModel>({})
		const sizeReferencesStatusModel = ref<ISizeReferencesStatusModel>({})
		const sizeTypesByAllCategoryGroup = ref<ISizeType[] | [] | null>(null)
		const sizeTypeModel = ref<string>('')
		const sizeTypeModelByMatch = ref<string | number>('')
		const sizeValuesByMatch = ref<ISizeValue[] | [] | null>(null)
		const selectedGenderIds = ref<Array<number>>([0, 1])
		const sizeValueNameModel = ref<string>('')
		const sizeValueKeyModel = ref<string>('')
		const isUniqName = ref<boolean>(false)
		const isUniqKey = ref<boolean>(false)
		const genders = toRaw([
			{
				name: 'women',
				key: 0,
				label: 'Женщины'
			},
			{
				name: 'men',
				key: 1,
				label: 'Мужчины'
			},
			{
				name: 'girls',
				key: 2,
				label: 'Девочки'
			},
			{
				name: 'boys',
				key: 3,
				label: 'Мальчики'
			}
		])

		function onChangeGenders(genders: Array<number>) {
			window.localStorage.setItem('selectedManagerNameInLocalStorage', JSON.stringify(genders))
			resetSizeValues()
		}

		const isSave = computed(
			() =>
				!!(sizeTypeModel.value && sizeValueNameModel.value && sizeValueKeyModel && isUniqName.value && isUniqKey.value)
		)

		onMounted(async () => {
			try {
				try {
					const localSavedGenders = window.localStorage.getItem(selectedManagerNameInLocalStorage)
					if (localSavedGenders) {
						selectedGenderIds.value = JSON.parse(localSavedGenders)
					}
				} catch (e) {}
				sizeTypes.value = (
					await axios.post<IResListWrap<ISizeType>>('/internal/get-size-types-list', { page: 0, limit: 300 })
				).data
			} catch (e) {}
		})

		function sizeValueKeyModelInput(str: string) {
			sizeValueKeyModel.value = str.replace(/[^(a-z|0-9|\-|_)]/g, '')

			if (!sizeValueKeyModel.value) return (isUniqKey.value = false)
			checkSizeValueByKey(sizeValueKeyModel.value)
		}

		function sizeValueNameModelInput() {
			if (!sizeValueNameModel.value) return (isUniqName.value = false)
			checkSizeValueByName(sizeValueNameModel.value)
		}

		function confirmSave() {
			ElMessageBox.confirm(
				'Хотите добавить значение размера: ' + sizeValueNameModel.value + ' и ключ: ' + sizeValueKeyModel.value,
				'',
				{
					boxType: 'confirm',
					type: 'info',
					confirmButtonText: 'Сохранить',
					cancelButtonText: 'Отменить'
				}
			).then(() => save())
		}

		async function save() {
			const data: Omit<ISizeValue, 'id'> = {
				key: sizeValueKeyModel.value,
				name: sizeValueNameModel.value,
				sizeTypeId: Number(sizeTypeModel.value)
			}

			try {
				await createSizeValue(data)

				ElMessage.success('Размер сохранен')

				reset()
			} catch (e) {
				ElMessage.error('Не удалось создать размер')
			}
		}

		function reset() {
			sizeValueKeyModel.value = ''
			sizeValueNameModel.value = ''
			// sizeTypeModel.value = ''
			isUniqKey.value = false
			isUniqName.value = false

			resetSizeValues()
		}

		const checkSizeValueByKey = debounce(200, async name => {
			const res = await apiCheckSizeValueByKey(name)

			if (res) {
				isUniqKey.value = false

				ElMessage.error('Ключ уже существует')
			} else isUniqKey.value = true
		})

		async function apiCheckSizeValueByKey(str: string): Promise<ISizeValue | null> {
			try {
				return (await axios.get<ISizeValue | null>('/internal/check-size-value-by-key/' + str)).data
			} catch (e) {
				return null
			}
		}

		const checkSizeValueByName = debounce(200, async name => {
			const res = await apiCheckSizeValueByName(name)

			if (res) {
				isUniqName.value = false

				ElMessage.error('Значение размера уже существует')
			} else isUniqName.value = true
		})

		async function apiCheckSizeValueByName(str: string): Promise<ISizeValue | null> {
			try {
				return (await axios.get<ISizeValue | null>('/internal/check-size-value-by-name/' + str)).data
			} catch (e) {
				return null
			}
		}

		const sizeTypeModelLabel = computed(() => {
			if (!sizeTypeModel.value || !sizeTypes?.value?.rows?.length) return null
			const sizeType = (sizeTypes.value.rows as ISizeType[]).find(size => size.id === +sizeTypeModel.value)
			return sizeType?.nameInternal || sizeType?.name
		})

		const sizeTypeModelByMatchLabel = computed(() => {
			if (!sizeTypeModelByMatch.value || !sizeTypesByAllCategoryGroup?.value?.length) return null
			const sizeType = (sizeTypesByAllCategoryGroup.value as ISizeType[]).find(
				size => size.id === +sizeTypeModelByMatch.value
			)
			return sizeType?.nameInternal || sizeType?.name
		})

		const selectedGenders = computed(() => {
			return genders.filter(gender => selectedGenderIds.value.includes(gender.key))
		})

		const canClearReferences = computed(() => sizeTypeModelByMatch.value && isTypeHasReferencesLink)

		async function resetSizeValues() {
			isFetchedReferences.value = false
			if (!sizeTypeModel.value) return (sizeValues.value = null)

			sizeTypeModelByMatch.value = ''
			isTypeHasReferencesLink.value = false

			sizeValues.value = await getSizeValuesBySizeTypeId(+sizeTypeModel.value)
			if (sizeValues.value?.length) {
				const sizeValueIds = (sizeValues.value as ISizeValue[]).map((size: ISizeValue) => size.id)
				sizeReferences.value = await getSizeReferencesBySizeValuesIds(sizeValueIds)
				sizeTypesByAllCategoryGroup.value = await getSizeTypeByAllCategoryGroup()
				if (sizeReferences.value?.length) {
					const sizeType = await getSizeTypeBySizeValueId(sizeReferences.value[0].sizeReferenceId)
					if (sizeType) {
						sizeTypeModelByMatch.value = sizeType.id
						onChangeSizeTypeModelByMatch(sizeType.id)
						isTypeHasReferencesLink.value = true
					}
				}
				setSizeReferencesModel(sizeValues.value, selectedGenders.value, sizeReferences.value)
				setSizeReferencesStatusModel(sizeValues.value, selectedGenders.value)
			}
			isFetchedReferences.value = true
		}

		function setSizeReferencesModel(
			sizeValues: ISizeValue[] | null,
			genders: { name: string; key: number; label: string }[],
			sizeReferences: ISizeReferences[] | [] | null
		) {
			if (!sizeValues) return
			for (const sizeValue of sizeValues) {
				sizeReferencesModel.value[sizeValue.id] = {}
				for (const gender of genders) {
					let value
					if (sizeReferences?.length) {
						const idx = sizeReferences?.findIndex(
							(sizeReference: ISizeReferences) =>
								sizeReference.sizeValueId === sizeValue.id && sizeReference.gender === gender.key
						)
						if (idx !== -1) {
							value = sizeReferences[idx].sizeReferenceId
						}
					}
					sizeReferencesModel.value[sizeValue.id][gender.key] = value
				}
			}
		}

		function setSizeReferencesStatusModel(
			sizeValues: ISizeValue[] | null,
			genders: { name: string; key: number; label: string }[]
		) {
			if (!sizeValues) return
			for (const sizeValue of sizeValues) {
				sizeReferencesStatusModel.value[sizeValue.id] = {}
				for (const gender of genders) {
					sizeReferencesStatusModel.value[sizeValue.id][gender.key] = {
						status: 0
					}
				}
			}
		}

		function confirmRemoveReferences() {
			ElMessageBox.confirm('Вы точно хотите удалить все соответствия? Их придется занести заново! Вы уверены?', {
				boxType: 'confirm',
				type: 'info',
				confirmButtonText: 'Удалить',
				cancelButtonText: 'Отменить'
			}).then(() => removeReferences())
		}

		async function removeReferences() {
			if (!sizeValues?.value?.length) return
			removeSizeReferencesStatus.value = 'Выполняется'

			const sizeValueIds = (sizeValues.value as ISizeValue[]).map((size: ISizeValue) => size.id)

			try {
				await axios.delete('/internal/remove-size-references-by-ids', {
					data: {
						sizeValueIds
					}
				})

				ElMessage.success('Удалены все соответствия')
			} catch (e) {
				ElMessage.error('Не удалось удалить все соответствия')
			} finally {
				removeSizeReferencesStatus.value = 'Выполнено'
				resetSizeValues()
			}
		}

		function confirmApplySizeReferencesToVariation(sizeValueId: number) {
			ElMessageBox.confirm(
				'Текущее соответствие будет применено ко всем вариациям, имеющим выбранные исходный размер и гендер',
				{
					boxType: 'confirm',
					type: 'info',
					confirmButtonText: 'Применить',
					cancelButtonText: 'Отменить'
				}
			).then(() => applySizeReferencesToVariation(sizeValueId))
		}

		async function applySizeReferencesToVariation(sizeValueId: number) {
			if (!sizeValues?.value?.length) return
			applySizeReferencesStatus.value[sizeValueId] = 'Выполняется'

			try {
				await axios.get('/internal/apply-size-reference/' + sizeValueId)

				ElMessage.success('Применены все соответствия к вариациям')
			} catch (e) {
				ElMessage.error('Не удалось применить все соответствия к вариациям')
			} finally {
				applySizeReferencesStatus.value[sizeValueId] = 'Выполнено'
			}
		}

		async function onChangeSizeTypeModelByMatch(sizeTypeModelByMatch: string | number) {
			sizeValuesByMatch.value = await getSizeValuesBySizeTypeId(+sizeTypeModelByMatch)
		}

		async function onChangeSizeValueModelByMatch(
			sizeValueByMatchId: number | boolean,
			genderKey: number,
			sizeValueId: number
		) {
			if (!sizeReferences.value?.length) {
				sizeReferences.value = []
			}
			const statusBody = {
				status: 3,
				color: '#888888',
				text: 'Выполняется'
			}
			sizeReferencesStatusModel.value[sizeValueId][genderKey] = statusBody
			if (sizeValueByMatchId === false) {
				await axios.delete('/internal/remove-size-references', {
					data: {
						gender: genderKey,
						sizeValueId
					}
				})
			} else {
				await axios.post('/internal/set-size-references', {
					sizeReferenceId: sizeValueByMatchId,
					gender: genderKey,
					sizeValueId
				})
			}
			const sizeValueIds = (sizeValues.value as ISizeValue[]).map((size: ISizeValue) => size.id)
			sizeReferences.value = await getSizeReferencesBySizeValuesIds(sizeValueIds)
			setSizeReferencesModel(sizeValues.value, selectedGenders.value, sizeReferences.value)
			if (sizeReferencesStatusModel.value?.[sizeValueId]?.[genderKey]) {
				const statusBody = {
					status: sizeValueByMatchId === false ? 2 : 1,
					color: sizeValueByMatchId === false ? '#FF0000' : '#1CC800',
					text: sizeValueByMatchId === false ? 'Удалено' : 'Записано'
				}
				sizeReferencesStatusModel.value[sizeValueId][genderKey] = statusBody
			}
		}

		async function getSizeValuesBySizeTypeId(id: number): Promise<ISizeValue[] | [] | null> {
			try {
				return (await axios.get<ISizeValue[] | []>('/internal/get-size-values-by-size-type-id/' + id)).data
			} catch (e) {
				return null
			}
		}

		async function getSizeTypeBySizeValueId(id: number): Promise<ISizeType | null> {
			try {
				return (await axios.get<ISizeType>('/internal/get-size-type-by-size-value-id/' + id)).data
			} catch (e) {
				return null
			}
		}

		async function getSizeReferencesBySizeValuesIds(
			sizeValueIds: Array<number>
		): Promise<ISizeReferences[] | [] | null> {
			try {
				return (
					await axios.post<ISizeReferences[] | []>('/internal/get-size-references-by-size-values-ids/', {
						sizeValueIds
					})
				).data
			} catch (e) {
				return null
			}
		}

		async function getSizeTypeByAllCategoryGroup(): Promise<ISizeType[] | [] | null> {
			try {
				return (await axios.get<ISizeType[] | []>('/internal/get-size-types-by-all-category-group')).data
			} catch (e) {
				return null
			}
		}

		async function createSizeValue(data: Omit<ISizeValue, 'id'>) {
			await axios.post('/internal/create-size-value', data)
		}

		return {
			isSave,
			sizeTypes,
			sizeValues,
			sizeTypeModel,
			sizeTypeModelLabel,
			sizeTypeModelByMatchLabel,
			sizeReferencesModel,
			sizeReferencesStatusModel,
			sizeTypesByAllCategoryGroup,
			sizeTypeModelByMatch,
			sizeValueNameModel,
			sizeValueKeyModel,
			selectedGenderIds,
			isFetchedReferences,
			isTypeHasReferencesLink,
			canClearReferences,
			sizeValuesByMatch,
			selectedGenders,
			removeSizeReferencesStatus,
			applySizeReferencesStatus,
			genders,

			isUniqName,
			isUniqKey,

			confirmSave,
			onChangeGenders,
			confirmApplySizeReferencesToVariation,
			confirmRemoveReferences,
			onChangeSizeTypeModelByMatch,
			onChangeSizeValueModelByMatch,
			resetSizeValues,
			sizeValueKeyModelInput,
			sizeValueNameModelInput
		}
	}
})
